<template>
    <v-navigation-drawer
        clipped
        id="navigation"
        :mini-variant-width="75"
        app
        color="primaryBackgroundColor"
        v-model="navigationDrawer"
        :mini-variant="navigationDrawerMini"
        :temporary="navigationTemporaryDrawer"
        fixed
        ref="navigationDrawer"
    >
        <v-list class="v-list-navigation" :height="10">
            <router-link
                class="router-link"
                v-for="(item, key) in userNavigationList"
                :key="key"
                :to="{ name: item.to }"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item link v-on="on">
                            <v-list-item-icon class="py-n5">
                                <v-btn fab small elevation="0" class="btn-icon">
                                    <div
                                        v-if="item.icon"
                                        class="v-icon v-icon-fill border-radius-25"
                                        style="width: 20px"
                                        v-html="
                                            require(`!html-loader!@/assets/img/${item.icon}.svg`)
                                        "
                                    ></div>
                                </v-btn>
                                <!-- <div v-if="(item.icon)" class="v-icon v-icon-fill border-radius-25" v-html="require(`!html-loader!@/assets/img/${item.icon}.svg`)"></div> -->
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    item.title
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <span>{{ item.title }}</span>
                </v-tooltip>
            </router-link>
        </v-list>

        <!-- <template v-slot:append>
      <v-list class="v-list-navigation" dense>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item @click="logout" v-on="on">
              <v-list-item-icon>
                <div class="v-icon v-icon-fill" v-html="require(`!html-loader!@/assets/img/sign-out.svg`)"></div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sign Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Sign Out</span>
        </v-tooltip>
      </v-list>
    </template> -->
    </v-navigation-drawer>
</template>

<script>
const navigationList = [
    {
        title: "Dashboard",
        to: "dashboard",
        icon: "dashboard",
        accessControl: "portal-dashboard",
    },
    // {
    //     title: "Home",
    //     to: "home",
    //     icon: "home",
    //     accessControl: "portal-home",
    // },
    {
        title: "Watch",
        to: "watch",
        icon: "watch",
        accessControl: "portal-watch",
    },
    {
        title: "Learn",
        to: "learn",
        icon: "learn",
        accessControl: "portal-learn",
    },
    {
        title: "Engage",
        to: "engage",
        icon: "engage",
        accessControl: "portal-engage",
    },
    {
        title: "Settings",
        to: "settings",
        icon: "settings",
        accessControl: "portal-settings",
    },
    {
        title: "Ministry",
        to: "ministry",
        icon: "users",
        accessControl: "portal-profile-management-users",
    },
];

export default {
    name: "navigation",
    data() {
        return {
            navigationDrawer: undefined,
            navigationTemporaryDrawer: undefined,
            navigationDrawerMini: undefined,
            temporaryDrawerPages: [],
            user: null,
            userNavigationList: [],
        };
    },
    computed: {
        isTemporaryDrawer() {
            return this.temporaryDrawerPages.includes(this.$route.name);
        },
    },
    methods: {
        initiate() {
            // Initial Value
            this.user = this.$cookies.getValue("user");
            this.setNavigationItems();
            this.navigationDrawer = this.isTemporaryDrawer
                ? false
                : this.navigationDrawer;
            this.navigationTemporaryDrawer = this.isTemporaryDrawer
                ? true
                : this.navigationTemporaryDrawer;

            this.events();

            // Set Data Example
            // this.$eventBus.$emit('set-navigation-value', { property: 'navigationTemporaryDrawer', value: true });
        },

        events() {
            this.$eventBus.$on("toogle-navigation", this.toggleNavigation);
            this.$eventBus.$on("set-navigation-value", (details) => {
                let { property, value } = details;
                this[property] =
                    typeof value == "boolean" ? value : this[property];
            });
        },

        toggleNavigation() {
            if (
                !this.$refs.navigationDrawer.isMobile &&
                !this.navigationTemporaryDrawer
            ) {
                this.navigationDrawerMini = !this.navigationDrawerMini;
            } else {
                this.navigationDrawerMini = false;
                this.navigationDrawer = !this.navigationDrawer;
            }
        },

        setNavigationItems() {
            const accessControls = this.user.access_controls;
            this.userNavigationList = accessControls
                .map((control) => {
                    if (control.have_access) {
                        return navigationList.find(
                            (item) => item.accessControl === control.slug
                        );
                    }
                })
                .filter((item) => item);
        }, // Based on user's access controls.
    },

    created() {
        this.initiate();
    },

    beforeDestroy() {
        this.$eventBus.$off("toogle-navigation");
        this.$eventBus.$off("set-navigation-value");
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#navigation {
    #logo-holder {
        height: 64px;
        padding-top: 4px;
        padding-bottom: 4px;

        #logo {
            // margin-left: 35px;
            margin: 0 auto;
            min-height: 32px;
            transition: margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
    }

    .v-list.v-list-navigation {
        padding: 0;

        .v-list-item {
            padding: -10px 10px;

            .v-list-item__icon {
                margin-right: 20px;
                .v-icon {
                    svg {
                        width: 30px;
                        height: 30px;
                    }

                    &.v-icon-fill {
                        svg {
                            path {
                                fill: #848c9c;
                            }

                            rect {
                                fill: #848c9c;
                            }

                            circle {
                                fill: #848c9c;
                            }
                        }
                    }

                    &.v-icon-stroke {
                        svg {
                            path {
                                stroke: #848c9c;
                            }

                            rect {
                                stroke: #848c9c;
                            }

                            circle {
                                stroke: #848c9c;
                            }
                        }
                    }
                }
            }

            .v-list-item__title {
                color: #848c9c;
            }
        }

        .router-link {
            text-decoration: none;

            &.router-link-active {
                .btn-icon {
                    background-color: var(--v-primaryTextColor-base);
                }
                .v-list-item {
                    // border-left: 5px solid var(--v-primary-base);
                    // background-color: var(--v-secondaryBackgroundColor-base);

                    &.theme--light {
                        // border-left: 5px solid var(--v-primary-base);
                        // color: var(--v-secondaryBackgroundColor-base);

                        .v-list-item__title {
                            color: var(--v-primaryTextColor-base);
                        }
                    }

                    .v-list-item__title {
                        color: rgba(0, 0, 0, 0.87);
                    }

                    .v-list-item__icon {
                        margin-left: 10px;

                        .v-icon-fill {
                            svg {
                                path {
                                    fill: white;
                                }

                                rect {
                                    fill: var(--v-primary-base);
                                }

                                circle {
                                    fill: var(--v-primary-base);
                                }
                            }
                        }

                        .v-icon-stroke {
                            svg {
                                path {
                                    stroke: var(--v-primary-base);
                                }

                                rect {
                                    stroke: var(--v-primary-base);
                                }

                                circle {
                                    stroke: var(--v-primary-base);
                                }
                            }
                        }
                    }
                }
            }
            .v-list-item__icon {
                margin-left: 10px;
            }
        }
    }

    // .v-navigation-drawer--mini-variant {
    //   #logo-holder #logo {
    //     margin-left: 0;
    //   }
    // }

    .v-navigation-drawer {
        &__prepend {
            #logo-holder {
                // border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
            }
        }
    }

    &.v-navigation-drawer--is-mobile {
        .v-navigation-drawer__content {
            overflow-y: auto;
        }
    }
}
</style>
